<!--
 * @Description: 联系人管理
 * @Author: ChenXueLin
 * @Date: 2021-10-08 11:13:03
 * @LastEditTime: 2022-07-01 09:25:58
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    :title="`公司账号-${corpName}`"
    :visible.sync="dialogVisible"
    width="70%"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="loading"
    :element-loading-background="loadingBackground"
    custom-class="edit-job-dialog edit-wrapper"
  >
    <el-form class="search-list" ref="searchForm" :model="searchForm">
      <el-form-item class="search-item--1" prop="userName">
        <el-input
          v-model="searchForm.userName"
          placeholder="用户名"
          title="用户名"
        ></el-input>
      </el-form-item>
      <el-form-item class="search-item--buttons">
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button class="reset" @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- 表格 start -->
    <section class="table-wrapper">
      <el-table
        border
        height="440"
        :data="tableData"
        highlight-current-row
        ref="elTable"
      >
        <!-- 序号 start -->
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.$index +
                  1 +
                  (searchForm.pageIndex - 1) * searchForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="column.fieldName === 'userCode'">
              <el-button type="text" @click="toE6yun(row)">{{
                row.userCode
              }}</el-button>
            </span>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <!-- 表格 end -->

    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
  </el-dialog>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getAccount, getUrl } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "companyAccount",
  components: {},
  data() {
    return {
      dialogVisible: false,
      loading: false,
      e6CorpId: "",
      corpName: "",
      searchForm: {
        userName: "", //场景名称
        pageIndex: 1,
        pageSize: 10
      },
      total: 0,
      tableData: [],
      columnData: [
        {
          fieldName: "userCode",
          display: true,
          fieldLabel: "账号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userName",
          display: true,
          fieldLabel: "用户名",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userLenveStr",
          display: true,
          fieldLabel: "用户等级",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "enableStatus",
          display: true,
          fieldLabel: "启用状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTimeWebgis",
          display: true,
          fieldLabel: "添加时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ] //名下场景表头
    };
  },
  mixins: [listPage, listPageReszie, base],
  computed: {},
  watch: {},
  methods: {
    init(row) {
      this.dialogVisible = true;
      this.e6CorpId = row.e6CorpId;
      this.corpName = row.corpName;
      this.queryList(row);
    },
    handleClose() {
      this.tableData = [];
      this.e6CorpId = "";
      this.corpName = "";
      this.dialogVisible = false;
    },
    async queryList() {
      try {
        this.loading = true;
        let res = await getAccount({
          ...this.searchForm,
          corpId: this.e6CorpId
        });
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //免登录至一流云
    toE6yun(row) {
      // 新窗口打开
      this.getUrlReq(row);
    },
    async getUrlReq(row) {
      try {
        this.loading = true;
        let res = await getUrl({
          ...this.searchForm,
          userCode: row.userCode,
          corpId: this.e6CorpId
        });
        if (res.data) {
          window.open(res.data, "_blank");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/ .edit-job-dialog {
  height: auto;
  .edit-title {
    padding: 0px;
  }
  .el-dialog__body {
    padding: 20px 20px 20px 20px;
  }
}
</style>
