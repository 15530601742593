<!--
 * @Description: 客户列表
 * @Author: LiangYiNing
 * @Date: 2021-10-08 09:19:15
 * @LastEditTime: 2022-08-22 18:29:32
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:operateAction>
          <i
            class="e6-icon-history"
            v-has="HISTORY_RECORDS_BUTTON_ID"
            title="历史记录"
            @click="toRecord"
          ></i>
          <i
            class="e6-icon-add_line"
            v-has="ADD_BUTTON_ID"
            title="新增"
            @click="addCustomer"
          ></i>
          <!-- <i class="e6-icon-clear_line" title="删除" @click="handleDelete"></i> -->
          <i
            class="e6-icon-export_line"
            v-has="EXPORT_BUTTON_ID"
            title="导出"
            @click="handleExport"
          ></i>
        </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="corpName">
              <el-input
                v-model.trim="searchForm.corpName"
                placeholder="客户名称"
                title="客户名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="attrId">
              <e6-vr-select
                v-model="searchForm.attrId"
                :data="corpAttrEnum"
                placeholder="客户属性"
                title="客户属性"
                :props="{
                  id: 'id',
                  label: 'attrValueDesc'
                }"
                multiple
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpCategory">
              <e6-vr-select
                v-model="searchForm.corpCategory"
                :data="corpCategoryEnum"
                placeholder="客户类别"
                title="客户类别"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpStatus">
              <e6-vr-select
                v-model="searchForm.corpStatus"
                :data="corpStatusEnum"
                placeholder="客户状态"
                title="客户状态"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="salesman">
              <all-user-search
                v-model="searchForm.salesman"
                clear
                placeholder="业务员"
                title="业务员"
                :propsConfig="{
                  id: 'employeeId',
                  label: 'userName'
                }"
                :showSlot="true"
                clearable
              >
              </all-user-search>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpBizType">
              <e6-vr-select
                v-model="searchForm.corpBizType"
                :data="corpBizTypeEnum"
                placeholder="客户业务类型"
                title="客户业务类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model.trim="searchForm.createdBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="updatedBy">
              <el-input
                v-model.trim="searchForm.updatedBy"
                placeholder="修改人"
                title="修改人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="remarkOp">
              <el-input
                v-model="searchForm.remarkOp"
                placeholder="业务备注"
                title="业务备注"
                maxlength="100"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="billingCorpId">
              <select-real-Time-query
                v-model="searchForm.billingCorpId"
                :queryListAPI="getInvoiceDownList"
                :showSlot="true"
                slotKey="taxNo"
                :popoverWidth="300"
                :searchData="{
                  pageIndex: 1,
                  pageSize: 200
                }"
                :propsConfig="{
                  id: 'billingCorpId',
                  label: 'billingCorpName'
                }"
                searchValueKey="billingCorpName"
                title="开票名称"
                placeholder="开票名称"
                clearable
              ></select-real-Time-query>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="createTime"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          @selection-change="handleSelectionChange"
        >
          <!--  @sort-change="sortChange" -->
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            show-overflow-tooltip
            label="序号"
            width="60"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            :show-overflow-tooltip="column.fieldName !== 'attrName'"
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :sortable="column.sortable"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'corpCategory'">
                {{ getCorpCategoryName(row[column.fieldName]) }}
              </span>
              <span v-else-if="column.fieldName === 'corpStatus'">
                {{ getCorpStatusName(row[column.fieldName]) }}
              </span>
              <el-tooltip
                v-else-if="column.fieldName === 'attrName'"
                class="item"
                effect="dark"
                :content="row.attrName"
                placement="right"
              >
                <div class="ellipsis">
                  {{ row.attrName }}
                </div>
              </el-tooltip>
              <span v-else-if="column.fieldName === 'corpDiff'">
                {{ getCorpDiffName(row[column.fieldName]) }}
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <company-account
      ref="companyAccount"
      v-if="companyAccountVisible"
    ></company-account>
  </div>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { exportData } from "@/utils/download";
import allUserSearch from "@/components/allUserSearch";

import CompanyAccount from "@/views/OMS/customerManage/customerList/companyAccount/companyAccount";
import selectRealTimeQuery from "@/components/selectRealTimeQuery";

import {
  getCorpList,
  deleteCorpList,
  exportCorpList,
  findDownList,
  getInvoiceDownList,
  getCorpAttrDownList
} from "@/api";
// 按钮权限常量
import setting from "@/setting";
let {
  ADD_BUTTON_ID,
  EXPORT_BUTTON_ID,
  DETAILS_BUTTON_ID,
  HISTORY_RECORDS_BUTTON_ID,
  UNDEFINED_3_BUTTON_ID // 公司账号
} = setting;

export default {
  name: "CustomerList",
  mixins: [listPage, listPageReszie, base],
  components: {
    allUserSearch,
    CompanyAccount,
    selectRealTimeQuery
  },
  data() {
    return {
      companyAccountVisible: false,
      tableScrollTop: 0,
      ADD_BUTTON_ID,
      EXPORT_BUTTON_ID,
      HISTORY_RECORDS_BUTTON_ID,
      getInvoiceDownList,
      searchForm: {
        corpName: "", //客户名称
        attrId: [], // 客户属性
        corpCategory: "", //客户类别
        corpStatus: "", //客户状态
        salesman: "", //业务员
        corpBizType: "", //业务类型
        startTime: "", //开始时间
        endTime: "", //结束时间
        createTime: [], //创建时间
        createdBy: "", //创建人
        updatedBy: "", //修改人
        remarkOp: "", //备注
        billingCorpId: "", // 开票名称
        pageIndex: 1,
        pageSize: 20,
        sortDir: "",
        sortIndex: ""
      },
      selColumnId: [], //选中的数据
      queryListAPI: getCorpList,
      timePickerResetList: ["createTime"], // base mixin的配置项 需要重置的timePicker refname
      corpDiffEnum: [], // 客户类型枚举
      corpCategoryEnum: [], // 客户类别枚举
      corpStatusEnum: [], // 客户状态枚举
      corpBizTypeEnum: [], // 客户业务类型枚举
      corpAttrEnum: [], // 客户属性枚举
      total: 0,
      columnData: [
        {
          fieldName: "corpId",
          fieldLabel: "客户ID",
          width: 80,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "crmId",
          fieldLabel: "crmId",
          width: 180,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "e6CorpId",
          fieldLabel: "e6CorpId",
          width: 90,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "companyName",
          fieldLabel: "所属主体",
          width: 180,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          fieldLabel: "系统客户名称",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "billingCorpName",
          fieldLabel: "开票名称",
          width: 160,
          sortable: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "attrName",
          display: true,
          fieldLabel: "客户属性",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpCategory",
          fieldLabel: "客户类别",
          width: 90,
          sortable: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpDiff",
          fieldLabel: "客户类型",
          width: 90,
          sortable: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpStatus",
          fieldLabel: "客户状态",
          width: 80,
          sortable: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "salesmanName",
          fieldLabel: "业务员",
          width: 110,
          sortable: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remarkOp",
          fieldLabel: "业务备注",
          width: 160,
          sortable: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdBy",
          fieldLabel: "创建人",
          width: 160,
          sortable: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          fieldLabel: "创建时间",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedBy",
          fieldLabel: "修改人",
          width: 160,
          sortable: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedTime",
          fieldLabel: "修改时间",
          width: 160,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [] // 表格数据
    };
  },
  computed: {},
  created() {
    this.queryList();
    this.initData();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.startTime = val[0] || "";
        this.searchForm.endTime = val[1] || "";
      }
    }
  },
  methods: {
    // 获取基础信息(枚举)
    async initData() {
      try {
        //获取枚举数据 客户类型 客户类别 客户状态 客户业务类别
        let promiseList = [
          findDownList([
            "corpDiff",
            "corpCategory",
            "corpStatus",
            "corpBizType"
          ]),
          getCorpAttrDownList({})
        ];
        let [findDownListRes, corpAttrRes] = await Promise.all(promiseList);
        this.corpDiffEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.corpDiff"
        });
        this.corpCategoryEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.corpCategory"
        });
        this.corpStatusEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.corpStatus"
        });
        this.corpBizTypeEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.corpBizType"
        });
        this.corpAttrEnum = this.getFreezeResponse(corpAttrRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    // 获取页面操作权限
    getOperateList() {
      let operationIds = this.$route.meta.operationIds || [];
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1,
          disabled: !operationIds.includes(DETAILS_BUTTON_ID)
        },
        {
          label: "公司账号",
          id: 2,
          disabled: !operationIds.includes(UNDEFINED_3_BUTTON_ID)
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row = {}) {
      if (val.id == 1) {
        this.routerPush({
          name: "customerManage/customerDetail",
          params: {
            refresh: true,
            corpId: row.corpId,
            corpName: row.corpName,
            origin: this.$route.name
          }
        });
      } else if (val.id == 2) {
        this.displayCompanyAccount(row);
      }
    },
    // 打开公司账号弹窗
    displayCompanyAccount(row) {
      this.companyAccountVisible = true;
      this.$nextTick(() => {
        this.$refs.companyAccount.init(row);
      });
    },
    // 日志
    toRecord() {
      this.routerPush({
        name: "customerRecord",
        params: {
          refresh: true
        },
        query: {}
      });
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.corpId;
      });
    },
    //批量删除
    handleDelete() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("是否确认删除所选客户信息?", "删除客户信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteCorp();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    //删除客户请求
    async deleteCorp() {
      try {
        let res = await deleteCorpList({
          ids: this.selColumnId
        });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 导出
    handleExport() {
      exportData(this, exportCorpList);
    },
    addCustomer() {
      this.routerPush({
        name: "customerManage/customerAdd",
        params: {
          refresh: true,
          origin: this.$route.name
        }
      });
    },
    // 获取客户类别名称
    getCorpCategoryName(val) {
      const target = this.corpCategoryEnum.find(item => item.codeValue == val);
      return target ? target.codeName : "";
    },
    // 获取客户状态名称
    getCorpStatusName(val) {
      const target = this.corpStatusEnum.find(item => item.codeValue == val);
      return target ? target.codeName : "";
    },
    // 获取客户状态名称
    getCorpDiffName(val) {
      const target = this.corpDiffEnum.find(item => item.codeValue == val);
      return target ? target.codeName : "";
    }
  }
};
</script>
<style lang="scss" scoped></style>
