var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-view-wrapper pd-10"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"sectionWrapper",staticClass:"content-view section-wrapper",attrs:{"element-loading-background":_vm.loadingBackground}},[_c('table-title',{on:{"resize":_vm.handleTableTitleResize},scopedSlots:_vm._u([{key:"operateAction",fn:function(){return [_c('i',{directives:[{name:"has",rawName:"v-has",value:(_vm.HISTORY_RECORDS_BUTTON_ID),expression:"HISTORY_RECORDS_BUTTON_ID"}],staticClass:"e6-icon-history",attrs:{"title":"历史记录"},on:{"click":_vm.toRecord}}),_vm._v(" "),_c('i',{directives:[{name:"has",rawName:"v-has",value:(_vm.ADD_BUTTON_ID),expression:"ADD_BUTTON_ID"}],staticClass:"e6-icon-add_line",attrs:{"title":"新增"},on:{"click":_vm.addCustomer}}),_vm._v(" "),_c('i',{directives:[{name:"has",rawName:"v-has",value:(_vm.EXPORT_BUTTON_ID),expression:"EXPORT_BUTTON_ID"}],staticClass:"e6-icon-export_line",attrs:{"title":"导出"},on:{"click":_vm.handleExport}})]},proxy:true},{key:"searchForm",fn:function(){return [_c('el-form',{ref:"searchForm",staticClass:"search-list",attrs:{"model":_vm.searchForm}},[_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"corpName"}},[_c('el-input',{attrs:{"placeholder":"客户名称","title":"客户名称"},model:{value:(_vm.searchForm.corpName),callback:function ($$v) {_vm.$set(_vm.searchForm, "corpName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"searchForm.corpName"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"attrId"}},[_c('e6-vr-select',{attrs:{"data":_vm.corpAttrEnum,"placeholder":"客户属性","title":"客户属性","props":{
                id: 'id',
                label: 'attrValueDesc'
              },"multiple":"","clearable":""},model:{value:(_vm.searchForm.attrId),callback:function ($$v) {_vm.$set(_vm.searchForm, "attrId", $$v)},expression:"searchForm.attrId"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"corpCategory"}},[_c('e6-vr-select',{attrs:{"data":_vm.corpCategoryEnum,"placeholder":"客户类别","title":"客户类别","props":{
                id: 'codeValue',
                label: 'codeName'
              },"clearable":""},model:{value:(_vm.searchForm.corpCategory),callback:function ($$v) {_vm.$set(_vm.searchForm, "corpCategory", $$v)},expression:"searchForm.corpCategory"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"corpStatus"}},[_c('e6-vr-select',{attrs:{"data":_vm.corpStatusEnum,"placeholder":"客户状态","title":"客户状态","props":{
                id: 'codeValue',
                label: 'codeName'
              },"clearable":""},model:{value:(_vm.searchForm.corpStatus),callback:function ($$v) {_vm.$set(_vm.searchForm, "corpStatus", $$v)},expression:"searchForm.corpStatus"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"salesman"}},[_c('all-user-search',{attrs:{"clear":"","placeholder":"业务员","title":"业务员","propsConfig":{
                id: 'employeeId',
                label: 'userName'
              },"showSlot":true,"clearable":""},model:{value:(_vm.searchForm.salesman),callback:function ($$v) {_vm.$set(_vm.searchForm, "salesman", $$v)},expression:"searchForm.salesman"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"corpBizType"}},[_c('e6-vr-select',{attrs:{"data":_vm.corpBizTypeEnum,"placeholder":"客户业务类型","title":"客户业务类型","props":{
                id: 'codeValue',
                label: 'codeName'
              },"clearable":""},model:{value:(_vm.searchForm.corpBizType),callback:function ($$v) {_vm.$set(_vm.searchForm, "corpBizType", $$v)},expression:"searchForm.corpBizType"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"createdBy"}},[_c('el-input',{attrs:{"placeholder":"创建人","title":"创建人"},model:{value:(_vm.searchForm.createdBy),callback:function ($$v) {_vm.$set(_vm.searchForm, "createdBy", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"searchForm.createdBy"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"updatedBy"}},[_c('el-input',{attrs:{"placeholder":"修改人","title":"修改人"},model:{value:(_vm.searchForm.updatedBy),callback:function ($$v) {_vm.$set(_vm.searchForm, "updatedBy", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"searchForm.updatedBy"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"remarkOp"}},[_c('el-input',{attrs:{"placeholder":"业务备注","title":"业务备注","maxlength":"100"},model:{value:(_vm.searchForm.remarkOp),callback:function ($$v) {_vm.$set(_vm.searchForm, "remarkOp", $$v)},expression:"searchForm.remarkOp"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"billingCorpId"}},[_c('select-real-Time-query',{attrs:{"queryListAPI":_vm.getInvoiceDownList,"showSlot":true,"slotKey":"taxNo","popoverWidth":300,"searchData":{
                pageIndex: 1,
                pageSize: 200
              },"propsConfig":{
                id: 'billingCorpId',
                label: 'billingCorpName'
              },"searchValueKey":"billingCorpName","title":"开票名称","placeholder":"开票名称","clearable":""},model:{value:(_vm.searchForm.billingCorpId),callback:function ($$v) {_vm.$set(_vm.searchForm, "billingCorpId", $$v)},expression:"searchForm.billingCorpId"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--2",attrs:{"prop":"createTime"}},[_c('e6-date-picker',{ref:"createTime",attrs:{"popper-class":"no-atTheMoment","label":"创建时间","title":"创建时间","picker-options":_vm.pickerOptions('searchForm.createTime'),"default-time":['00:00:00', '23:59:59'],"start-placeholder":"创建时间（始）","end-placeholder":"创建时间（止）"},model:{value:(_vm.searchForm.createTime),callback:function ($$v) {_vm.$set(_vm.searchForm, "createTime", $$v)},expression:"searchForm.createTime"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--buttons"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("查询")]),_vm._v(" "),_c('el-button',{staticClass:"reset",on:{"click":_vm.handleReset}},[_vm._v("重置")])],1)],1)]},proxy:true}])}),_vm._v(" "),_c('section',{staticClass:"table-wrapper"},[_c('el-table',{ref:"tableList",attrs:{"border":"","height":_vm.resizeViewHeight + 'px',"data":_vm.tableData,"highlight-current-row":""},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"show-overflow-tooltip":"","label":"序号","width":"60","fixed":"left","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v("\n              "+_vm._s(scope.$index +
                  1 +
                  (_vm.searchForm.pageIndex - 1) * _vm.searchForm.pageSize)+"\n            ")])]}}])}),_vm._v(" "),_vm._l((_vm.columnData),function(column,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":column.fieldName !== 'attrName',"prop":column.fieldName,"label":column.fieldLabel,"min-width":column.width,"fixed":column.fixed,"sortable":column.sortable,"align":column.align,"header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [(column.fieldName === 'corpCategory')?_c('span',[_vm._v("\n              "+_vm._s(_vm.getCorpCategoryName(row[column.fieldName]))+"\n            ")]):(column.fieldName === 'corpStatus')?_c('span',[_vm._v("\n              "+_vm._s(_vm.getCorpStatusName(row[column.fieldName]))+"\n            ")]):(column.fieldName === 'attrName')?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.attrName,"placement":"right"}},[_c('div',{staticClass:"ellipsis"},[_vm._v("\n                "+_vm._s(row.attrName)+"\n              ")])]):(column.fieldName === 'corpDiff')?_c('span',[_vm._v("\n              "+_vm._s(_vm.getCorpDiffName(row[column.fieldName]))+"\n            ")]):_c('span',[_vm._v(_vm._s(row[column.fieldName]))])]}}],null,true)})}),_vm._v(" "),_c('el-table-column',{attrs:{"fixed":"right","align":"center","label":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('e6-td-operate',{attrs:{"data":_vm.getOperateList()},on:{"command":function($event){return _vm.handleOperate($event, scope.row)}}})]}}])})],2)],1),_vm._v(" "),_c('section',{staticClass:"pagination-wrapper fixed-section"},[_c('el-pagination',{attrs:{"page-size":_vm.searchForm.pageSize,"current-page":_vm.searchForm.pageIndex,"page-sizes":_vm.pageSizes,"layout":_vm.layout,"total":_vm.total,"background":""},on:{"update:pageSize":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:currentPage":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"update:current-page":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1),_vm._v(" "),(_vm.companyAccountVisible)?_c('company-account',{ref:"companyAccount"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }